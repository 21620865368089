const logotext = "JASON";
const meta = {
  title: "Jason Schwartz",
  description:
    "I’m Jason Schwartz, an IT Tech with aspirations of working in cybersecurity living in Los Angeles",
};

const introdata = {
  title: "I’m Jason Schwartz",
  animated: {
    first: "IT Support Technician",
    second: "Cybersecurity Professional",
    third: "Computer Engineer",
    fourth: "Climber / Gamer",
  },
  description:
    "Results-driven cybersecurity professional seeking a challenging role to apply expertise in information security, risk management, and incident response to contribute to the protection of organizational assets.",
  your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
  title: "A Bit About Myself",
  aboutme:
    "Hello, my name is Jason Schwartz. I am a detail-oriented and results-driven professional with over three years of experience in IT and a solid academic background in computer engineering. Currently working in retail, I am excited to bring my technical skills and passion for cybersecurity to a dynamic entry-level role.",
};
const worktimeline = [
  {
    jobtitle: "IT Support Technician",
    where: "American Health Connection",
    date: "2024-Present",
  },
  {
    jobtitle: "Retail Gearhead",
    where: "Backcountry",
    date: "2023-2024",
  },
  {
    jobtitle: "Systems Administrator, IT",
    where: "City of Beverly Hills",
    date: "2019-2022",
  },
  {
    jobtitle: "Field Service Technician",
    where: "Robert Half",
    date: "2022",
  },
];

const certificates = [
  {
    certname: "Google Cybersecurity Specialization",
    issuer: "Coursera",
    issuedate: "December 2023",
  },
  {
    certname: "Meta Front-End Developer",
    issuer: "Coursera",
    issuedate: "April 2023",
  },
  // {
  //   certname: "Retail Gearhead",
  //   issuer: "Backcountry",
  //   issuedate: "2023-Present",
  // },
];

const skills = [
  {
    name: "Python",
    value: 90,
  },
  {
    name: "Javasacript",
    value: 85,
  },
  {
    name: "Linux",
    value: 80,
  },
  {
    name: "React",
    value: 80,
  },
  // {
  //   name: "Jquery",
  //   value: 85,
  // },
];

const services = [
  {
    title: "UI & UX Design",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Mobile Apps",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Wordpress Design",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
];

const dataportfolio = [
  {
    img: "https://picsum.photos/400/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/800/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/600/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/300/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/700/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },

  {
    img: "https://picsum.photos/400/600/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/300/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/550/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/700/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
];

const contactConfig = {
  YOUR_EMAIL: "jasonkobes24@gmail.com",
  YOUR_FONE: "(310)770-4328",
  description: "I'm looking forward to hearing from you!",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_04s88rc",
  YOUR_TEMPLATE_ID: "template_se5s1y4",
  YOUR_USER_ID: "T1WXyc-jYJQUjXN0M",
};

const socialprofils = {
  github: "https://github.com/JSchwartz24",
  facebook: "https://facebook.com",
  linkedin: "https://linkedin.com/in/jasonkschwartz/",
  twitter: "https://twitter.com",
};
export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
  certificates,
};
